import React, { useState } from "react";
import MenueButton from "./common/menueButton";
import { Modal, Button } from "react-bootstrap";
import QueryBuilder from "../queryBuilder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const FilterData = ({ whereQuery }) => {
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState("");
  const [tree, setTree] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handelQuery = (obj) => {
    setQuery(obj.query);
    setTree(obj.tree);
  };

  const handleReset = () => {
    whereQuery("");
    setTree(undefined);
  };

  return (
    <React.Fragment>
      <MenueButton
        label="Filter Data"
        btnClasses="btn btn-secondary"
        onClick={handleShow}
        data-tut="reactour__filter--table"
      >
        <FontAwesomeIcon icon={faFilter} color="white" />
      </MenueButton>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filter Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QueryBuilder onSqlQuery={handelQuery} tree={tree} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleReset}>
            Reset Filter
          </Button>
          <Button variant="primary" onClick={() => whereQuery(query)}>
            <FontAwesomeIcon icon={faFilter} /> Apply Filter
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default FilterData;
