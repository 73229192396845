import L from "leaflet";

const getLocalStorage = (property) => {
  try {
    return JSON.parse(localStorage.getItem("user"))[property];
  } catch (error) {}
};

const default_config = {
  tableName: "gor_strategic_doc_georef", // TODO: could be moved to admin app
  apiKey: getLocalStorage("api_key"),
  cartoUrl: getLocalStorage("carto_url"),
  apiEndpoint: `${getLocalStorage("carto_url")}/user/admin/api/v2/sql`,
  authEndpoint:
    "https://geografia-user-admin.herokuapp.com/api/planning_docs_user.json",
  sentryDns: "https://bdf7cd3b3a42404699f91613bd6454df@sentry.io/4588562",
  testShpLink:
    "https://delwp.s3.amazonaws.com/public/test-data-for-uploads-delwp.shp.zip",
  forgotPasswordMailTo:
    "mailto:support@geografia.com.au?subject=DELWP PDS: Forgotten Password",
  supportMailTo:
    "mailto:support@geografia.com.au?subject=DELWP PDS: Support Inquiry",
  initialBounds: L.latLngBounds(
    L.latLng(-39.10448880944048, 138.32885742187503),
    L.latLng(-37.78808138412046, 148.42529296875003)
  ),
};

const config = {
  production: { ...default_config, tableName: "planning_documents_production" },
  staging: { ...default_config },
  development: { ...default_config },
  testing: { ...default_config },
};

export default config[process.env.NODE_ENV];
