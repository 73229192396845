import React from "react";

const MenueButton = ({ label, children, btnClasses, onClick, ...rest }) => {
  return (
    <button type="button" className={btnClasses} onClick={onClick} {...rest}>
      {children} {label}
    </button>
  );
};

export default MenueButton;
