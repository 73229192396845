export function alignPropertiesWithColNames(fileProperties, COLUMN_NAMES) {
  const properties = {};
  const propKeys = Object.keys(fileProperties);
  for (const propKey of propKeys) {
    for (const col of COLUMN_NAMES) {
      if (propKey.toLowerCase() === col.toLowerCase()) {
        properties[col] = fileProperties[propKey];
      }
    }
  }

  return properties;
}

export function buildColumnsOfInsert(COLUMN_NAMES) {
  let colArray = [...COLUMN_NAMES];
  colArray.push("the_geom");
  return `(${colArray.join(",")})`;
}

export function buildValueOfInsert(COLUMN_NAMES, properties, geometry) {
  let valArray = COLUMN_NAMES.map(col => {
    let value = properties[col];
    value = value ? value.replace("&", "and") : value;
    return `'${value}'`;
  });

  valArray.push(
    `St_SetSRID(ST_GeomFromGeoJSON('${JSON.stringify(geometry)}'), 4326)`
  );

  return `(${valArray.join(",")})`;
}
