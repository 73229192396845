import React from "react";

const SearchBox = ({ value, onSearch }) => {
  return (
    <input
      type="text"
      name="query"
      className="col-6 form-control"
      placeholder="Search"
      style={{ marginTop: 10 }}
      value={value}
      onChange={(e) => onSearch(e.currentTarget.value)}
    />
  );
};

export default SearchBox;
