import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";

const NavListItem = ({ label, onClick, children, ...rest }) => {
  return (
    <li className="nav-item" onClick={onClick} {...rest}>
      <a className="nav-link" href="#">
        {children} {label}
      </a>
    </li>
  );
};

export default NavListItem;
