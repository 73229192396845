import React, { Component } from "react";
import TableHead from "./tableHead";
import TableBody from "./TableBody";
import Details from "./details";
import Loader from "react-loader-spinner";

class Table extends Component {
  render() {
    const {
      data,
      columns,
      sortColumn,
      onSort,
      tableToggle,
      tableLoading
    } = this.props;

    return (
      <React.Fragment>
        {tableLoading ? (
          <div style={{ position: "relative" }}>
            <div className="centerText" style={{ marginTop: 100 }}>
              <Loader
                type="Grid"
                color="#292b2c"
                height={100}
                width={100}
                // timeout={3000} //3 secs
              />
            </div>
          </div>
        ) : !tableToggle ? (
          <div className="row justify-content-center p-2 ">
            <div className="col-12 ">
              <table
                id="tableId"
                className="table table-responsive table-bordered table-fixed"
              >
                <TableHead
                  columns={columns}
                  sortColumn={sortColumn}
                  onSort={onSort}
                />
                <TableBody
                  data={data}
                  columns={columns}
                  onZoomClick={this.props.onZoomClick}
                  onDeleteClick={this.props.onDeleteClick}
                />
              </table>
            </div>
          </div>
        ) : (
          <Details data={data} columns={columns}></Details>
        )}
      </React.Fragment>
    );
  }
}

export default Table;
