import React, { Component } from "react";
import {
  Query,
  Builder,
  BasicConfig,
  Utils
} from "react-awesome-query-builder";
import {
  DOC_TITLE,
  DOC_TYPE,
  MUNICIPALI,
  STATUS,
  YEAR_PREP,
  DESCRIPTION,
  YEAR_TO,
  STATUS_DES
} from "./constants/COLUMN_NAMES";

// You need to provide your own config. See below 'Config format'
const config = {
  ...BasicConfig,
  fields: {
    [DOC_TITLE]: {
      label: "Document Title",
      type: "text",
      valueSources: ["value"]
    },
    [DOC_TYPE]: {
      label: "Document Type",
      type: "text",
      valueSources: ["value"]
    },
    [MUNICIPALI]: {
      label: "Municipality",
      type: "text",
      valueSources: ["value"]
    },
    [STATUS]: {
      label: "Status",
      type: "select",
      valueSources: ["value"],
      listValues: [
        { value: "Approved", title: "Approved" },
        { value: "Reference Document", title: "Reference Document" },
        { value: "Adopted", title: "Adopted" },
        { value: "Under Assessment", title: "Under Assessment" },
        { value: "Draft", title: "Draft" }
      ]
    },
    [YEAR_PREP]: {
      label: "Year Prep",
      type: "text",
      valueSources: ["value"]
    },
    [DESCRIPTION]: {
      label: "Description",
      type: "text",
      valueSources: ["value"]
    },
    [STATUS_DES]: {
      label: "Status Des",
      type: "text",
      valueSources: ["value"]
    },
    [YEAR_TO]: {
      label: "Year To",
      type: "text",
      valueSources: ["value"]
    }
  }
};

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const queryValue = { id: Utils.uuid(), type: "group" };

class QueryBuilder extends Component {
  state = {
    tree: this.props.tree
      ? this.props.tree
      : Utils.checkTree(Utils.loadTree(queryValue), config),
    config: config
  };

  render = () => {
    console.log("this.props.tree", this.props.tree);

    const tree = this.props.tree
      ? this.props.tree
      : Utils.checkTree(Utils.loadTree(queryValue), config);

    return (
      <div>
        <Query
          {...config}
          value={tree}
          onChange={this.onChange}
          renderBuilder={this.renderBuilder}
        />
        {this.renderResult(this.state)}
      </div>
    );
  };

  renderBuilder = props => (
    <div className="query-builder-container" style={{ padding: "10px" }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  seeValue = query => {
    console.log("query", query);
  };
  renderResult = ({ tree: immutableTree, config }) => (
    <div className="query-builder-result">
      {/* <div>
        Query string:{" "}
        <pre>{JSON.stringify(Utils.queryString(immutableTree, config))}</pre>
      </div>
      <div>
        MongoDb query:{" "}
        <pre>{JSON.stringify(Utils.mongodbFormat(immutableTree, config))}</pre>
      </div>
      <div>
        SQL where:{" "}
        <pre>{JSON.stringify(Utils.sqlFormat(immutableTree, config))}</pre>
      </div>
      <div>
        JsonLogic:{" "}
        <pre>
          {JSON.stringify(Utils.jsonLogicFormat(immutableTree, config))}
        </pre>
      </div>
      <div>
        TreeLogic:{" "}
        <pre> {Utils.checkTree(Utils.loadTree(immutableTree), config)}</pre>
      </div> */}
    </div>
  );

  onChange = (immutableTree, config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`

    if (JSON.stringify(Utils.sqlFormat(immutableTree, config))) {
      this.props.onSqlQuery({
        query: JSON.stringify(Utils.sqlFormat(immutableTree, config)),
        tree: Utils.checkTree(Utils.loadTree(immutableTree), config)
      });
    }

    this.setState({ tree: immutableTree, config: config });

    const jsonTree = Utils.getTree(immutableTree);
    // console.log(jsonTree);
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  };
}

export default QueryBuilder;
