import http from "./httpService";
import config from "../config";

export function login(username, password) {
  return http.get(config.authEndpoint, {
    auth: {
      username: username,
      password: password
    }
  });
}

export function isLoggedIn() {
  if (!localStorage.getItem("token")) return false;
  else return true;
}

export function isAdmin() {
  const { username } = JSON.parse(localStorage.getItem("user"));
  return username.substring(username.length - 5) === "admin";
}

export function getName() {
  return JSON.parse(localStorage.getItem("user")).name;
}
