import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Input = ({ name, label, value, error, type, onChange, icon }) => {
  return (
    <React.Fragment>
      <div className="input-group mb-3">
        {/* <div className="form-group"> */}
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon1">
            <FontAwesomeIcon icon={icon} />
          </span>
        </div>
        {/* <label htmlFor={name}>{label}</label> */}
        <input
          value={value}
          onChange={onChange}
          name={name}
          id={name}
          type={type}
          className="form-control"
          placeholder={label}
        />
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </React.Fragment>
  );
};

export default Input;
