import React, { Component } from "react";
import ViewsDropDown from "./viewsDropdown";
import About from "./about";
import FeatureExtent from "./featureExtent";
import UserOptions from "./userOptions";
import logo from "../assets/Final-Geografia-Logos_White.png";
class NavBar extends Component {
  render() {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark sticky-top navbar-tour"
        style={{
          backgroundImage:
            "linear-gradient(135deg, rgb(32, 22, 69) 85.9%, rgb(0, 183, 189) 14.1%)",
        }}
      >
        <a className="navbar-brand font-weight-bold" href="#">
          Planning Documents Management System
        </a>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <About />
            {/* <Charts /> */}
            <FeatureExtent
              onExtentClick={() => this.props.onExtentClick(true)}
            />
            <ViewsDropDown
              view={this.props.view}
              onViewChange={this.props.onViewChange}
            />
          </ul>
        </div>
        <a
          className="navbar-brand"
          target="_blank"
          rel="noopener noreferrer"
          href="https://geografia.com.au/"
        >
          <img width="131.25" height="35" src={logo} alt="Logo" />
        </a>

        <UserOptions onShowTour={this.props.onShowTour} />
      </nav>
    );
  }
}

export default NavBar;
