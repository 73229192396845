import alasql from "alasql";
import _ from "lodash";

function getFilteredData({ data, searchQuery, filterQuery, ...rest }) {
  let filteredData = data;
  if (searchQuery) {
    filteredData = data.filter(({ properties }) =>
      search(properties, searchQuery)
    );
  }
  if (filterQuery) {
    const result = filteredData.map((row) => row.properties);

    try {
      const res = alasql(`SELECT * FROM ? WHERE ${filterQuery}`, [result]);

      const tempData = [];
      filteredData.filter((row) =>
        res.map((obj) => {
          if (_.isEqual(obj, row.properties)) {
            tempData.push(row);
          }
        })
      );
      filteredData = tempData;
    } catch (error) {
      console.log("error in query execution");
    }
  }
  return { ...rest, data: filteredData };
}

function search(properties, searchQuery) {
  const query = searchQuery.toLowerCase();
  const values = Object.values(properties);
  for (let value of values) {
    if (value && value.toString().toLowerCase().includes(query)) return true;
  }

  return false;
}

export default getFilteredData;
