import React, { Component } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import carto from "@carto/carto-vl";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import config from "../config";
import { getModalMapFitBounds, getRowById } from "../services/sqlService";

function fitBounds(cartodb_id, map) {
  fetch(getModalMapFitBounds(cartodb_id))
    .then(resp => {
      return resp.json();
    })
    .then(response => {
      const extent = /BOX\((.+) (.+),(.+) (.+)\)/
        .exec(response.rows[0].extent)
        .splice(1, 4)
        .map(e => parseFloat(e));
      let bounds = [
        [extent[0], extent[1]], //lng,lat
        [extent[2], extent[3]] //lng,lat
      ];
      map.fitBounds(bounds);
    })
    .catch(error => {
      console.error("Error:", error);
    });
}

const handleLoadedLayer = (map, layer) => {
  if (layer.getNumFeatures()) {
    let f = layer.getFeaturesAtPosition(0)[0];
    fitBounds(f.id, map);
  }
};

class IdentityMap extends Component {
  componentDidMount() {
    this.map = null;
    setTimeout(() => {
      this.map = new mapboxgl.Map({
        container: "identityMapId",
        style: carto.basemaps.voyager,
        center: [142.48799, -38.38176],
        zoom: 6,
        scrollZoom: false
      });

      const nav = new mapboxgl.NavigationControl({
        showCompass: false
      });
      this.map.addControl(nav, "top-left");
      this.map.addControl(new mapboxgl.FullscreenControl(), "top-left");

      const scale = new mapboxgl.ScaleControl({
        maxWidth: 80,
        unit: "metric"
      });
      this.map.addControl(scale);

      carto.setDefaultAuth({
        username: "admin",
        apiKey: config.apiKey
      });

      // Define layer with SQL source
      const source = new carto.source.SQL(
        getRowById(this.props.cartodb_id),
        {
          user: "admin",
          apiKey: config.apiKey
        },
        { serverURL: `${config.cartoUrl}/user/{user}` }
      );

      const viz = new carto.Viz(`
        width: 8
        color: rgba(106,90,205,0.2)
        strokeWidth: 0.5
        strokeColor: #191970
      `);

      const layer = new carto.Layer("layer", source, viz);

      layer.addTo(this.map, "watername_ocean");
      layer.on("loaded", e => handleLoadedLayer(this.map, layer));
    }, 100);
  }

  render() {
    return (
      <div className="col-12">
        <div
          id="identityMapId"
          style={{
            height: "100%",
            width: "100%",
            overflow: "hidden"
          }}
        />
      </div>
    );
  }
}

export default IdentityMap;
