import React from "react";
import {
  DOC_TITLE,
  DOC_TYPE,
  MUNICIPALI,
  STATUS,
  YEAR_PREP,
  DESCRIPTION,
  YEAR_TO,
  STATUS_DES,
  LINK,
  CARTODB_ID,
} from "../constants/COLUMN_NAMES";

const columnsConfig = [
  {
    key: CARTODB_ID,
    name: "id",
    minWidth: 60,
    isChecked: true,
  },
  {
    key: DOC_TITLE,
    name: "Document Title",
    minWidth: 150,
    isChecked: true,
  },
  { key: DOC_TYPE, name: "Document Type", minWidth: 150, isChecked: true },
  { key: MUNICIPALI, name: "Municipality", minWidth: 150, isChecked: true },
  { key: STATUS, name: "Status", minWidth: 100, isChecked: true },
  { key: YEAR_PREP, name: "Year Prep", minWidth: 120, isChecked: true },
  { key: DESCRIPTION, name: "Description", minWidth: 250, isChecked: true },
  { key: YEAR_TO, name: "Year To", minWidth: 100, isChecked: true },
  {
    key: STATUS_DES,
    name: "Status Description",
    minWidth: 200,
    isChecked: true,
  },
  {
    key: LINK,
    name: "Link",
    minWidth: 50,
    isChecked: true,
    content: (link) => {
      return link && link.startsWith("http") ? (
        <a target="_blank" rel="noopener noreferrer" href={link}>
          Download
        </a>
      ) : (
        "N/A"
      );
    },
  },
];

export default columnsConfig;
