import React from "react";
import { Dropdown } from "react-bootstrap";
import { getName } from "../services/authService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserAlt,
  faRoute,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

const UserOptions = ({ onShowTour }) => {
  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location = "/";
  };
  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-light" id="dropdown-version">
        <FontAwesomeIcon icon={faUserAlt} /> {getName()}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ zIndex: 10000 }}>
        <Dropdown.Item className="text-primary" onClick={onShowTour}>
          <FontAwesomeIcon icon={faRoute} /> Start Tour
        </Dropdown.Item>
        <Dropdown.Item className="text-primary" onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} /> Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserOptions;
