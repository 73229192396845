import React, { Component } from "react";
import Identity from "./identity";
import renderCell from "../utils/renderCell";
import { isAdmin } from "../services/authService";
import ConfirmDeleteDialogue from "./ConfirmDeleteDialogue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";

class TableBody extends Component {
  render() {
    const { data, columns } = this.props;

    return (
      <tbody>
        {data.map((data) => (
          <tr key={data.properties.cartodb_id}>
            <td className="text-center">
              <span
                onClick={() =>
                  this.props.onZoomClick(data.properties.doc_title)
                }
              >
                <FontAwesomeIcon
                  icon={faSearchPlus}
                  title="zoom"
                  color="blue"
                  className="action-icon"
                  data-tut="reactour__zoom--document"
                />
              </span>

              <Identity data={data} columns={columns} />
              {isAdmin() && (
                <span>
                  <ConfirmDeleteDialogue
                    onDeleteClick={this.props.onDeleteClick}
                    cartodb_id={data.properties.cartodb_id}
                  />
                </span>
              )}
            </td>
            {columns.map((column) => {
              if (column.isChecked) {
                return <td key={column.name}>{renderCell(data, column)}</td>;
              }
            })}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
