import React from "react";
import config from "../config";

const Support = () => {
  return (
    <div>
      <p>
        For queries regarding the use of this product please contact the
        Geografia team at <a href={config.supportMailTo}>support email</a>.
        Alternatively you may reach us via our contact details here:{" "}
        <a
          href="https://geografia.com.au/about-us"
          target="_blank"
          rel="noopener noreferrer"
        >
          About Geografia
        </a>
      </p>
    </div>
  );
};

export default Support;
