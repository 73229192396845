export default function apiBuilder(apiEndpoint, queryParamters) {
  let queryParamterStr = "";
  const queryParam = (key, value) => {
    return key + "=" + value;
  };
  Object.keys(queryParamters).map((key, index) => {
    if (!index) {
      queryParamterStr += "?" + queryParam(key, queryParamters[key]);
    } else {
      queryParamterStr += "&" + queryParam(key, queryParamters[key]);
    }
  });

  return apiEndpoint + queryParamterStr;
}
