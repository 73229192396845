import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const ConfirmDeleteDialogue = ({ onDeleteClick, cartodb_id }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <FontAwesomeIcon
        icon={faTrashAlt}
        onClick={handleShow}
        color="red"
        className="action-icon"
        title="Delete"
      />

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to delete this row? This row will be deleted from database
          permanently
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary"
            variant="secondary"
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            variant="btn btn-danger"
            onClick={() => onDeleteClick(cartodb_id)}
          >
            Delete Anyway
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmDeleteDialogue;
