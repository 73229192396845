import React from "react";
import config from "../config";

const tourStepsConfig = [
  // {
  //   selector: ".navbar-tour",
  //   content: () => (
  //     <div className="tour-content">This is main navigation bar of application. You will find controls for the view of application as well as user related controls such as logout.</div>
  //   ),
  // },
  {
    selector: '[data-tut="reactour__map--reset"]',
    content: () => (
      <div className="tour-content">
        <h3>&#x1F502; Reset Map</h3>
        This navigation button resets the map to its default extents, showing all records.
      </div>
    ),
  },
  {
    selector: '[data-tut="reactour__views"]',
    content: () => (
      <div className="tour-content">
        <h3>&#x1F453; App Views</h3>
        You can switch between the following 3 application views
        <ul>
          <li>
            <b>&#x1F9EC; Split View</b> (Default. Displays both the map & table)
          </li>
          <li>
            {" "}
            <b>&#x1F30F; Map View</b> (Displays only the map on screen)
          </li>
          <li>
            <b>&#x1F4CA; Table View</b> (Displays only the table on screen)
          </li>
        </ul>
      </div>
    ),
  },
  {
    selector: '[data-tut="reactour__map"]',
    content: () => (
      <div className="tour-content">
        <h3>&#x1F5FA; Map</h3>
        <strong>Markers</strong> on the map represent the{" "}
        <strong>centre of the area</strong>, for which the planning documents are
        applicable. You can click on them to get full details of the planning document.
      </div>
    ),
  },
  {
    selector: ".map-control-tour",
    content: () => (
      <div className="tour-content">
        <h3>&#x1F371; Map Layers</h3>
        You may switch between following layers on the map
        <ul>
          <li>&#x1F5FA; Street Map (Default)</li>
          <li>&#x1F6F0; Aerial Imagery</li>
          <li>&#x1F3E1; Parcels Overlay Layer</li>
        </ul>
      </div>
    ),
  },
  {
    selector: ".map-wms-tour",
    content: () => (
      <div className="tour-content">
        <h3>&#x1F3D8; Parcel Layer</h3>
        The Parcel Layer is activated when the zoom level is <b>16+</b>. You will
        see a notification in the top-right of the screen when this happens.
      </div>
    ),
  },

  {
    selector: '[data-tut="reactour__filter--table"]',
    content: () => (
      <div className="tour-content">
        <h3>&#x1F308; Filter Documents</h3>
       This widget allows you to filter records by their properties. When the filter is applied changes will be reflected on both the map and the table.
      </div>
    ),
  },
  {
    selector: '[data-tut="reactour__export--table"]',
    content: () => (
      <div className="tour-content">
        <h3>&#x1F4E5; Export Table</h3>
        You can export the document table in the following three formats:
        <ul>
          <li>CSV</li>
          <li>Excel</li>
          <li>PDF</li>
        </ul>
        Note: If you have applied a filter then only records which meet the visibility criterion will be exported.
      </div>
    ),
  },
  {
    selector: '[data-tut="reactour__upload--documents"]',
    content: () => (
      <div className="tour-content">
        <h3>&#x1F4E4; Upload Documents</h3>
        This widget allows you to upload additional records. The following constraints apply when uploading new data:
        <ul>
          <li>
            &#x1F5C4; You can only upload one <b>zipped Shapefile at a time</b>
          </li>
          <li>&#x1F522; The file should not contain more than 20 records</li>
          <li>
            &#x1F4C1; The file should follow{" "}
            <a href={config.testShpLink}>this file</a> structure
          </li>
        </ul>
      </div>
    ),
  },

  {
    selector: '[data-tut="reactour__toggle--table"]',
    content: () => (
      <div className="tour-content">
        <h3>&#x1F500; Toggle Table</h3>
    By clicking this button, you can toggle between the table view and the details view of records. 
      </div>
    ),
  },
  {
    selector: '[data-tut="reactour__filter--columns"]',
    content: () => (
      <div className="tour-content">
        <h3>&#x1F648; Hide Columns</h3>
        By checking and un-checking their names from this list, you can show or hide columns from the table. 
      </div>
    ),
  },
  {
    selector: '[data-tut="reactour__zoom--document"]',
    content: () => (
      <div className="tour-content">
        <h3>&#x1F50D; Zoom Document</h3>
        This button focuses on a specific record on the map
      </div>
    ),
  },
  {
    selector: '[data-tut="reactour__info--document"]',
    content: () => (
      <div className="tour-content">
        <h3>&#x1F5D2; Document Details</h3>
      By clicking this icon, you can see details of a particular record. This includes:
        <ul>
          <li>All the information about the planning document </li>
          <li>Areas that come under the planning document</li>
          <li>
            Related planning documents this document falls under (geographically).
          </li>
        </ul>
      </div>
    ),
  },
];

export default tourStepsConfig;
