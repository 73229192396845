import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAlphaUp,
  faSortAlphaDownAlt,
  faSort,
  faCog
} from "@fortawesome/free-solid-svg-icons";

class TableHead extends Component {
  raiseSort = path => {
    const sortColumn = { ...this.props.sortColumn };

    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    this.props.onSort(sortColumn);
  };

  renderSortIcon = path => {
    const { sortColumn } = this.props;

    if (path !== sortColumn.path)
      return (
        <FontAwesomeIcon icon={faSort} color="grey" className="sort-icon" />
      );
    if (sortColumn.order === "asc")
      return (
        <FontAwesomeIcon
          icon={faSortAlphaUp}
          className="sort-icon"
          color="grey"
        />
      );
    return (
      <FontAwesomeIcon
        icon={faSortAlphaDownAlt}
        className="sort-icon"
        color="grey"
      />
    );
  };

  render() {
    const { columns } = this.props;
    return (
      <thead>
        <tr>
          <th scope="col" style={{ whiteSpace: "nowrap", minWidth: 90 }}>
            <FontAwesomeIcon
              icon={faCog}
              color="grey"
              style={{
                float: "left",
                marginRight: 5,
                marginTop: 3,
                padding: 0.5
              }}
            />
            Action
          </th>
          {columns.map(column => {
            if (column.isChecked)
              return (
                <th
                  onClick={() => this.raiseSort(`properties.${column.key}`)}
                  key={column.name}
                  scope="col"
                  style={{ whiteSpace: "nowrap", minWidth: column.minWidth }}
                >
                  {column.name}{" "}
                  {this.renderSortIcon(`properties.${column.key}`)}
                </th>
              );
          })}
        </tr>
      </thead>
    );
  }
}

export default TableHead;
