import React from "react";
import { useDropzone } from "react-dropzone";
import shp from "shpjs";
import config from "../config";
import _ from "lodash";
import Joi from "joi-browser";
import { getInsertDataQuery } from "../services/sqlService";
import {
  alignPropertiesWithColNames,
  buildColumnsOfInsert,
  buildValueOfInsert,
} from "../utils/dropzoneUtils";
import pipe from "../utils/common_utils/pipe";
import {
  DOC_TITLE,
  DOC_TYPE,
  MUNICIPALI,
  STATUS,
  YEAR_PREP,
  DESCRIPTION,
  YEAR_TO,
  STATUS_DES,
  LINK,
  COLUMN_NAMES,
} from "../constants/COLUMN_NAMES";

const DropZone = () => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    getFilesFromEvent: (event) => myCustomFileGetter(event),
  });

  const files = acceptedFiles.map((f) => {
    const textColor = f.isFileUploaded.value ? "green" : "red";
    return (
      <p
        className="centerText"
        style={{
          fontSize: 18,
          color: textColor,
          fontWeight: "bold",
        }}
        key={f.name}
      >
        {f.isFileUploaded.message}{" "}
        {f.isFileUploaded.link ? (
          <a href={config.testShpLink}>sample Shapefile structure</a>
        ) : null}
      </p>
    );
  });

  return (
    <section className="container">
      {_.isEmpty(files) ? (
        <div
          {...getRootProps({ className: "dropzone" })}
          style={{ height: 300, borderStyle: "double", position: "relative" }}
        >
          <input {...getInputProps()} />
          <p
            className="centerText"
            style={{
              fontSize: 22,
            }}
          >
            Drag 'n' drop one zipped shapefile. The file may have up to 20
            records. or click <button className="btn btn-info btn-sm">here to upload</button> a shapefile
          </p>
        </div>
      ) : (
        <div
          style={{
            height: 100,
            borderStyle: "double",
          }}
        >
          {files}
        </div>
      )}
    </section>
  );
};

async function myCustomFileGetter(event) {
  const files = [];

  const fileList = event.dataTransfer
    ? event.dataTransfer.files
    : event.target.files;

  upperloop: for (var i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);
    const buffer = await file.arrayBuffer();

    // check zip file is zip shapefile
    let features = [];
    try {
      features = shp.parseZip(buffer).features;
    } catch (error) {
      Object.defineProperty(file, "isFileUploaded", {
        value: {
          values: false,
          message: "File should be a valid Zipped Shapfile",
        },
      });
      files.push(file);
      break;
    }
    // check rows are less than 20
    if (features.length > 20) {
      Object.defineProperty(file, "isFileUploaded", {
        value: {
          values: false,
          message: "Features should be less than 20",
        },
      });
      files.push(file);
      break;
    }

    // validate schema
    const schema = {
      cartodb_id: Joi.string().allow("", null).optional(),
      [DOC_TITLE]: Joi.string().required(),
      [DOC_TYPE]: Joi.string().allow("", null).optional(),
      [STATUS]: Joi.string().allow("", null).optional(),
      [DESCRIPTION]: Joi.string().allow("", null).optional(),
      [MUNICIPALI]: Joi.string().allow("", null).optional(),
      [YEAR_TO]: Joi.string().allow("", null).optional(),
      [YEAR_PREP]: Joi.string().allow("", null).optional(),
      [STATUS_DES]: Joi.string().allow("", null).optional(),
      [LINK]: Joi.string().allow("", null).optional(),
    };

    // build column statement
    const columns = buildColumnsOfInsert(COLUMN_NAMES);

    let err = "";
    let values = features.map((feature) => {
      const { properties: fileProperties, geometry } = feature;

      // align properties keys to the table in carto so that
      // we can make correct insert query
      const properties = alignPropertiesWithColNames(
        fileProperties,
        COLUMN_NAMES
      );

      const { error } = Joi.validate(properties, schema, {
        abortEarly: false,
      });
      if (error) {
        err = "error";
        return;
      }

      return buildValueOfInsert(COLUMN_NAMES, properties, geometry);
    });

    if (err === "error") {
      Object.defineProperty(file, "isFileUploaded", {
        value: {
          values: false,
          message: `Invalid Shapefile, Shapefile should conform to this `,
          link: true,
        },
      });
      files.push(file);
      return files;
    }

    values.join(",");

    const insertQuery = getInsertDataQuery(columns, values);
    const headers = {
      "content-type": "application/x-www-form-urlencoded",
    };

    const { status } = await fetch(config.apiEndpoint, {
      headers,
      body: `q=${insertQuery}&api_key=${config.apiKey}`,
      method: "POST",
      mode: "cors",
    });
    if (status === 200) {
      Object.defineProperty(file, "isFileUploaded", {
        value: {
          value: true,
          message: `${features.length} Document Records Successfully Uploaded`,
        },
      });
    }

    files.push(file);
  }

  return files;
}
export default DropZone;
