import React from "react";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    className="nav-link"
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

export default CustomToggle;
