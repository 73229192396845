import React from "react";
import NavListItem from "./common/navListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAlt } from "@fortawesome/free-solid-svg-icons";

const FeatureExtent = ({ onExtentClick }) => {
  return (
    <NavListItem
      label="Feature Extent"
      onClick={onExtentClick}
      data-tut="reactour__map--reset"
    >
      <FontAwesomeIcon icon={faArrowsAlt} />
    </NavListItem>
  );
};

export default FeatureExtent;
