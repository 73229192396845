import React from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

const ColumnFilterDropdown = ({ columns, onColumnSelect }) => {
  return (
    <Dropdown alignRight="false" data-tut="reactour__filter--columns">
      <Dropdown.Toggle variant="secondary" id="dropdown-column-filter">
        <FontAwesomeIcon icon={faList} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {columns.map((column) => (
          <div
            key={column.name}
            className="checkbox"
            style={{ display: "inline" }}
          >
            <label>
              <input
                className="m-2"
                type="checkbox"
                onChange={(e) => onColumnSelect(column)}
                id={column.key}
                checked={column.isChecked}
              />
              {column.name}
            </label>
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ColumnFilterDropdown;
