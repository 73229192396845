import _ from "lodash";
import getFilteredData from "./filterData";
import compose from "./common_utils/compose";

export const getTableData = (obj) => {
  return compose(sortData, getFilteredData, getMapBoundData)(obj);
};

export const getMapData = ({ data, searchQuery, filterQuery }) => {
  return getFilteredData({
    data,
    searchQuery,
    filterQuery,
  }).data;
};

const sortData = ({ data, path, order }) => _.orderBy(data, [path], [order]);

function getMapBoundData(obj) {
  const data = obj.mapBoundRows.length
    ? obj.data.filter((data) =>
        obj.mapBoundRows.some(
          (row) => data.properties.doc_title === row.properties.doc_title
        )
      )
    : obj.data;

  return { ...obj, data: data };
}
