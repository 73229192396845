import React from "react";

const AboutTheProject = () => (
  <div>
    <p>
      This Planning Documents Management System (PDMS) is the product of a DELWP
      project to undertake a planning context analysis and prepare a planning
      pathway guideline. These form a framework for cooperative decision-making
      by the Standing Advisory Committee and proponents of visitor economy-based
      development proposals in the Great Ocean Road region.{" "}
      <a
        href="https://www.echelonplanning.com.au/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Echelon Planning
      </a>{" "}
      undertook the research work, in partnership with{" "}
      <a
        href="https://geografia.com.au/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Geografia
      </a>
      , who designed and built this system.
    </p>
    <p>
      The PDMS includes a spatial database of planning policy documents,
      planning permits, amendments and other related material to support the
      Minister for Planning and the Standing Advisory Committee in their work.
    </p>
    Built by{" "}
    <a
      href="https://geografia.com.au/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Geografia Pty Ltd
    </a>
    .
  </div>
);

export default AboutTheProject;
