import React, { Component } from "react";
import _ from "lodash";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../App.scss";
import Table from "../components/table";
import NavBar from "../components/navBar";
import TableMenue from "../components/tableMenue";
import Map from "../components/map";
import { getTableData, getMapData } from "../utils/dataPipelines";

import convertToTableData from "../utils/convertToTableData";
import http from "../services/httpService";
import config from "../config";
import getCorrectOrderLatLng from "../utils/getCorrectOrderLatLng";
import {
  getAllDocumentsQuery,
  getDeleteRowQuery,
} from "../services/sqlService";
import ToastUndo from "./ToastUndo";
import setTableHeight from "../utils/setTableHeight";
import setMapHeight from "../utils/setMapHeight";
import Tour from "reactour";
import columnsConfig from "./columnsConfig";
import tourStepsConfig from "./tourStepsConfig";
import L from "leaflet";

class Main extends Component {
  state = {
    data: [],
    tableLoading: true,
    searchQuery: "",
    filterQuery: "",
    config: { tableToggle: false, view: "split" }, // view values "split", "map", "table"
    isChecked: true,
    sortColumn: { path: `properties.cartodb_id`, order: "asc" },
    // extentMap: true,
    mapBoundRows: [],
    zoomCoordinate: [],
    // Buffer
    deletedRows: [],
    isTourOpen: false,
    mapBounds: config.initialBounds,
    resetBounds: false,
  };

  closeTour = () => {
    localStorage.removeItem("isTourOpen");
    this.setState({ isTourOpen: false });
  };

  componentDidMount() {
    let isTourOpen = false;

    if (localStorage.getItem("isTourOpen") === "true") {
      isTourOpen = true;
    }

    getAllDocumentsQuery();
    http.get(getAllDocumentsQuery()).then(({ data }) =>
      this.setState({
        data: data.features,
        tableLoading: false,
        isTourOpen,
      })
    );
  }

  handleResetBounds = (val) => {
    this.setState({ resetBounds: val, mapBounds: config.initialBounds });
  };
  handleSearch = (searchQuery) => {
    this.setState({ searchQuery });
  };

  handleTableToggle = () => {
    const config = { ...this.state.config };
    config.tableToggle = !config.tableToggle;
    this.setState({ config });
  };

  handleColumnSelect = (column) => {
    const index = columnsConfig.indexOf(column);
    columnsConfig[index].isChecked = !columnsConfig[index].isChecked;

    const value = this.state.isChecked;
    this.setState({ isChecked: !value });
  };

  handleViewChange = (view) => {
    const config = { ...this.state.config };
    config.view = view;
    this.setState({ config });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  getCsvData = (data) => {
    let { tableBody, tableHead } = convertToTableData(data, columnsConfig);
    tableBody.unshift(tableHead);
    return tableBody;
  };

  downloadPdf = (sortedData) => {
    let { tableBody, tableHead } = convertToTableData(
      sortedData,
      columnsConfig
    );

    const pdfHeight = 150 + tableBody.length * 30;
    const pdfWidth = 700 + tableHead.length * 150;

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [pdfWidth, pdfHeight],
    });

    doc.autoTable({
      head: [tableHead],
      body: tableBody,
    });

    doc.save("table.pdf");
  };

  handleWhereQuery = (query) => {
    query = query.slice(1, -1);
    this.setState({ filterQuery: query });
  };

  getExcelData = (sortedData) => {
    const excelBody = sortedData.map((obj) => obj.properties);
    const excelColumns = columnsConfig.filter((column) => column.isChecked);
    return { excelBody, excelColumns };
  };

  handleZoomClick = (doc_title) => {
    const data = this.state.data.filter(
      (data) => data.properties.doc_title === doc_title
    )[0];
    this.setState({ zoomCoordinate: data.geometry.coordinates });
  };

  handleExtent = () => {
    const mapBounds = config.initialBounds;

    this.setState({ mapBounds, resetBounds: true });
  };

  handleMapBoundedMarkers = (markers, mapBounds) => {
    const data = this.state.data.filter((row) => {
      for (let i = 0; i < markers.length; i++) {
        let marker = getCorrectOrderLatLng(row.geometry.coordinates);
        if (_.isEqual(markers[i], marker)) return true;
      }
      return false;
    });

    // to limit app rendering on each moveend event
    if (!_.isEqual(this.state.mapBounds, mapBounds)) {
      this.setState({ mapBoundRows: data, mapBounds });
    }
  };

  undo = (id) => {
    const deletedRow = this.state.deletedRows.filter(
      (row) => row.properties.cartodb_id === id
    );
    let data = [...this.state.data, ...deletedRow];
    data = _.orderBy(data, ["properties.cartodb_id"], ["asc"]);

    const deletedRows = this.state.deletedRows.filter(
      (row) => row.properties.cartodb_id !== id
    );

    this.setState({ data, deletedRows });
  };

  // Remove definitely
  cleanCollection = (id) => {
    const deletedRow = this.state.deletedRows.filter(
      (row) => row.properties.cartodb_id === id
    );

    if (!deletedRow.length) return;

    const deleteQuery = getDeleteRowQuery(id);
    const headers = {
      "content-type": "application/x-www-form-urlencoded",
    };

    fetch(config.apiEndpoint, {
      headers,
      body: `q=${deleteQuery}&api_key=${config.apiKey}`,
      method: "POST",
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        toast.error("Something failed while deleting a post");
        const deletedRow = this.state.deletedRows.filter(
          (row) => row.properties.cartodb_id === id
        );
        let data = [...this.state.data, ...deletedRow];
        data = _.orderBy(data, ["properties.cartodb_id"], ["asc"]);

        const deletedRows = this.state.deletedRows.filter(
          (row) => row.properties.cartodb_id !== id
        );

        this.setState({ data, deletedRows });
      });
  };

  handleRowDelete = (id) => {
    // deletes from render immediately
    let deletedRow = {};
    const data = this.state.data.filter((row) => {
      if (row.properties.cartodb_id === id) {
        deletedRow = row;
      }

      return row.properties.cartodb_id !== id;
    });

    const deletedRows = [...this.state.deletedRows, deletedRow];

    this.setState({
      data,
      deletedRows,
    });

    toast(<ToastUndo undo={this.undo} id={id} />, {
      // hook will be called whent the component unmount
      onClose: () => this.cleanCollection(id),
    });
  };

  handleShowTour = () => this.setState({ isTourOpen: true });

  render() {
    const {
      data,
      mapBoundRows,
      searchQuery,
      filterQuery,
      config,
      sortColumn,
    } = this.state;

    console.log("main rendered");
    const mapData = getMapData({
      data,
      searchQuery,
      filterQuery,
    });

    /* For table data chronology of filters are important
       mapBoundRows filters should be applied before search
       & filter query
    */
    let rowsData = getTableData({
      data,
      mapBoundRows,
      searchQuery,
      filterQuery,
      path: sortColumn.path,
      order: sortColumn.order,
    });

    const excelData = this.getExcelData(rowsData);
    const csvData = this.getCsvData(rowsData);

    return (
      <React.Fragment>
        <ToastContainer />

        <NavBar
          onViewChange={this.handleViewChange}
          onExtentClick={this.handleResetBounds}
          onShowTour={this.handleShowTour}
          view={config.view}
        ></NavBar>
        <main className="container-fluid" style={{ height: "56px" }}>
          <div className="row" style={setMapHeight(config.view)}>
            <Map
              data={mapData}
              columns={columnsConfig}
              onMapBoundedMarkers={this.handleMapBoundedMarkers}
              resetBounds={this.state.resetBounds}
              zoomCoordinate={this.state.zoomCoordinate}
              mapBounds={this.state.mapBounds}
              onResetBounds={this.handleResetBounds}
            />
          </div>

          <div className="table-block" style={setTableHeight(config.view)}>
            <TableMenue
              rowsData={mapData}
              tableToggle={config.tableToggle}
              columns={columnsConfig}
              rowsCount={rowsData.length}
              onSearch={this.handleSearch}
              onColumnSelect={this.handleColumnSelect}
              onTableToggle={this.handleTableToggle}
              csvData={csvData}
              excelData={excelData}
              downloadPdf={this.downloadPdf}
              whereQuery={this.handleWhereQuery}
              searchQuery={this.state.searchQuery}
            />

            <Table
              data={rowsData}
              columns={columnsConfig}
              onSort={this.handleSort}
              sortColumn={sortColumn}
              tableToggle={config.tableToggle}
              onZoomClick={this.handleZoomClick}
              onDeleteClick={this.handleRowDelete}
              tableLoading={this.state.tableLoading}
            />
          </div>
        </main>
        <Tour
          steps={tourStepsConfig}
          accentColor="#5cb7b7"
          rounded={5}
          className="reactour"
          isOpen={this.state.isTourOpen}
          onRequestClose={this.closeTour}
        />
      </React.Fragment>
    );
  }
}

export default Main;
