import React, { Component } from "react";
import $ from "jquery";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.browser.print/dist/leaflet.browser.print";
import L from "leaflet";
import "leaflet.markercluster";
import defaultIcon from "../utils/defaultIcon";
import ModalDetails from "./modalDetails";
import _ from "lodash";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";
import "esri-leaflet";
import * as Geocoding from "esri-leaflet-geocoder";
import getCorrectOrderLatLng from "../utils/getCorrectOrderLatLng";
import { toast } from "react-toastify";
import properties2table from "../utils/properties2table";
import { withRouter } from "react-router-dom";
import generateReportUrl from "../services/reportUrlService";

// require "../services/L.TileLayer.BetterWMS";

const getContentFunc = async (properties) => {
  // end point for fetching the token for fetching parcel
  const baseURI =
    "https://www2.delwp.vic.gov.au/maps/property-dashboard/street_suggestions.json";
  const baseURIReports =
    "https://www2.delwp.vic.gov.au/maps/maps-and-spatial-report";

  let tokenApi = `${baseURI}?extraQuery=amendment-id&profile=amendment-id&partial_query=${encodeURI(
    properties["PARCEL_SPI"]
  )}`;

  let result = "";

  try {
    const response = await fetch(
      "https://cors-anywhere.herokuapp.com/" + tokenApi
    );
    const tokenData = await response.json();
    // combine the text and key (where text is parcel id and key is a token)
    const propValue = encodeURIComponent(
      `${tokenData[0]["text"]},${tokenData[0]["key"]}`
    );
    result = `${baseURIReports}?property=${propValue}`;
  } catch (err) {
    result = `https://services.land.vic.gov.au/landchannel/content/pdfreport?reportType=detailed&source=propertyportal&propertypfi=${properties["PARCEL_PFI"]}`;
  }

  return properties2table({
    "<strong>Property Report</strong>": `<strong>
      <a class="btn btn-sm btn-warning" target='_blank' 
        rel='noopener noreferrer' 
        href=${result}>
          <i class="fa fa-external-link" aria-hidden="true"></i> Standard Parcel Identifier: ${properties["PARCEL_SPI"]}
      </a>
    </strong>`,
    ...properties,
  });
};

//services.land.vic.gov.au/landchannel/content/pdfreport?propertypfi=420996621&reportType=vicplan&source=planportal

http: L.TileLayer.BetterWMS = L.TileLayer.WMS.extend({
  onAdd: function (map) {
    // Triggered when the layer is added to a map.
    //   Register a click listener, then do all the upstream WMS things
    L.TileLayer.WMS.prototype.onAdd.call(this, map);
    map.on("click", this.getFeatureInfo, this);
  },

  onRemove: function (map) {
    // Triggered when the layer is removed from a map.
    //   Unregister a click listener, then do all the upstream WMS things
    L.TileLayer.WMS.prototype.onRemove.call(this, map);
    map.off("click", this.getFeatureInfo, this);
  },

  getFeatureInfo: function (evt) {
    if (evt.target._zoom && evt.target._zoom < 16) {
      return;
    }
    toast.info("Loading feature ...", {
      toastId: "wmsClickLoading",
      position: "top-center",
      // autoClose: 10000
    });
    // console.log(evt);
    // Make an AJAX request to the server and hope for the best
    var url = this.getFeatureInfoUrl(evt.latlng),
      showResults = L.Util.bind(this.showGetFeatureInfo, this);
    // debugger;
    $.ajax({
      url: url,
      success: function (data, status, xhr) {
        showResults(null, evt.latlng, data);
        // toast.dismiss("wmsClickLoading");
      },
      error: function (xhr, status, error) {
        showResults(error);
      },
    });
  },

  getFeatureInfoUrl: function (latlng) {
    // Construct a GetFeatureInfo request URL given a point
    var point = this._map.latLngToContainerPoint(latlng, this._map.getZoom()),
      size = this._map.getSize(),
      params = {
        request: "GetFeatureInfo",
        service: "WMS",
        srs: "EPSG:4326",
        styles: this.wmsParams.styles,
        transparent: this.wmsParams.transparent,
        version: this.wmsParams.version,
        format: this.wmsParams.format,
        bbox: this._map.getBounds().toBBoxString(),
        height: size.y,
        width: size.x,
        layers: this.wmsParams.layers,
        query_layers: this.wmsParams.layers,
        info_format: "application/json",
      };

    params[params.version === "1.3.0" ? "i" : "x"] = parseInt(point.x);
    params[params.version === "1.3.0" ? "j" : "y"] = parseInt(point.y);

    return this._url + L.Util.getParamString(params, this._url, true);
  },

  showGetFeatureInfo: async function (
    err,
    latlng,
    content
    // getContentFunc = getContentFunc
  ) {
    if (err) {
      console.log(err);
      return;
    } // do nothing if there's an error

    // Otherwise show the content in a popup, or something.

    if (latlng && content && content.features && content.features.length) {
      // debugger;
      // console.log(latlng);
      // console.log(content.features.length);
      let wmsSelectionLayer = this._map.getLayerById("wmsSelection");
      wmsSelectionLayer.addData(content.features[0]);

      let contentHtml = await getContentFunc(content.features[0].properties);
      toast.dismiss("wmsClickLoading");
      L.popup({ maxWidth: 800 })
        .setLatLng(latlng)
        .setContent(contentHtml)
        .openOn(this._map);
    }
  },
});

L.tileLayer.betterWms = function (url, options) {
  return new L.TileLayer.BetterWMS(url, options);
};

const ZoomViewer = L.Control.extend({
  onAdd: function (map) {
    var container = L.DomUtil.create("div");
    var gauge = L.DomUtil.create("div");
    container.style.width = "100px";
    container.style.background = "rgba(255,255,255,0.6)";
    container.style.textAlign = "center";
    container.style.marginLeft = "2px";
    map.on("zoomstart zoom zoomend", function (ev) {
      gauge.innerHTML = "Zoom level: " + map.getZoom();
    });
    container.appendChild(gauge);

    return container;
  },
});

L.Map.include({
  getLayerById: function (id) {
    for (var i in this._layers) {
      if (this._layers[i].id == id) {
        return this._layers[i];
      }
    }
  },
});

class Map extends Component {
  state = {
    show: false,
    modalData: {},
    zoomLevel: 7.5,
    mapCenter: {},
  };

  polygon = null;

  handleClose = () => {
    this.props.history.push("/");
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  componentDidMount() {
    // L.mapbox.accessToken =
    //   "pk.eyJ1Ijoic2FiIiwiYSI6IlB2NDg2NDQifQ.-0pGhgotPTf4OzAZRO6eUg";

    // Basemap Layers
    // const mapboxOSM = L.tileLayer(
    //   "https://{s}.tiles.mapbox.com/v4/mapbox.streets/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoic2FiIiwiYSI6IlB2NDg2NDQifQ.-0pGhgotPTf4OzAZRO6eUg",
    //   {
    //     maxZoom: 22,
    //     subdomains: ["a", "b", "c", "d"],
    //     attribution:
    //       'Basemap <a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox © OpenStreetMap</a>',
    //   }
    // );

    const mapboxOSM = L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        attribution:
          '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
        tileSize: 512,
        maxZoom: 18,
        zoomOffset: -1,
        id: "mapbox/streets-v11",
        accessToken:
          "pk.eyJ1Ijoic2FiIiwiYSI6IlB2NDg2NDQifQ.-0pGhgotPTf4OzAZRO6eUg",
      }
    );

    // const mapboxSat = L.tileLayer(
    //   "https://{s}.tiles.mapbox.com/v4/mapbox.streets-satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoic2FiIiwiYSI6IlB2NDg2NDQifQ.-0pGhgotPTf4OzAZRO6eUg",
    //   {
    //     maxZoom: 22,
    //     subdomains: ["a", "b", "c", "d"],
    //     attribution:
    //       'Basemap <a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox © OpenStreetMap</a>',
    //   }
    // );

    const mapboxSat = L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        attribution:
          '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
        tileSize: 512,
        maxZoom: 18,
        zoomOffset: -1,
        id: "mapbox/satellite-v9",
        accessToken:
          "pk.eyJ1Ijoic2FiIiwiYSI6IlB2NDg2NDQifQ.-0pGhgotPTf4OzAZRO6eUg",
      }
    );

    const wmsLayer = L.tileLayer.betterWms(
      "https://services.land.vic.gov.au/catalogue/publicproxy/guest/dv_geoserver/wms?",
      {
        layers: "VMPROP_PARCEL_MP",
        transparent: true,
        opacity: 0.75,
        minZoom: 16,
        maxZoom: 22,
        format: "image/png",
        interactive: true,
        className: "wmsLayer",
      }
    );

    const overlayLayers = { "Parcels (zoom 16+)": wmsLayer };

    this.map = L.map("mapId", {
      layers: [mapboxOSM],
    });

    this.map.addLayer(wmsLayer);
    Geocoding.geosearch({
      useMapBounds: 17,
    }).addTo(this.map);

    //searchLayer is a L.LayerGroup contains searched markers
    var baseMaps = {
      "Street Map": mapboxOSM,
      "Aerial Imagery": mapboxSat,
    };

    let baseMapLayer = L.control
      .layers(baseMaps, overlayLayers, { collapsed: false })
      .addTo(this.map);
    // adding reactour class to layer
    baseMapLayer.getContainer().childNodes[1].childNodes[2].className +=
      " map-wms-tour";
    baseMapLayer.getContainer().className += " map-control-tour";

    L.control.scale().addTo(this.map);
    L.control.browserPrint().addTo(this.map);

    this.markerGroup = L.markerClusterGroup();

    // add zoom

    new ZoomViewer().addTo(this.map);

    // wmsSelection Layer

    this.wmsSelection = L.geoJSON(null, {
      onEachFeature: async function (f, l) {
        l.bindPopup(await getContentFunc(f.properties));
      },
      style: {
        color: "#ff7800",
        weight: 5,
        opacity: 0.65,
      },
    });
    this.wmsSelection.id = "wmsSelection";

    this.map.addLayer(this.wmsSelection);
  }

  handleMarkerClick(modalData) {
    this.setState({
      modalData,
      show: true,
    });
    this.props.history.push(`#${this.state.modalData.properties.cartodb_id}`);
  }

  componentDidUpdate(prevProps, prevState) {
    // Typical usage (don't forget to compare props):

    if (this.props.resetBounds) {
      this.props.onResetBounds(false);
    }
    this.map.fitBounds(this.props.mapBounds);
    this.map.removeLayer(this.markerGroup);
    this.markerGroup = L.markerClusterGroup({
      maxClusterRadius: 120,
      iconCreateFunction: function (cluster) {
        var childCount = cluster.getChildCount();

        var c = " marker-cluster-";
        if (childCount < 10) {
          c += "small";
        } else if (childCount < 100) {
          c += "medium";
        } else {
          c += "large";
        }

        return new L.DivIcon({
          html: "<div><span>" + childCount + "</span></div>",
          className: "marker-cluster" + c,
          iconSize: new L.Point(40, 40),
        });
      },
      //Disable all of the defaults:
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: true,
      zoomToBoundsOnClick: true,
    });

    const markers = this.props.data.map((row) => {
      let marker = getCorrectOrderLatLng(row.geometry.coordinates);

      this.markerGroup.addLayer(
        L.marker(marker, {
          icon: defaultIcon,
          title: row.properties.doc_title,
        }).on("click", () => this.handleMarkerClick(row))
      );

      return marker;
    });
    this.map.addLayer(this.markerGroup);

    // for getting markers inside visible map region

    const { onMapBoundedMarkers, mapBounds } = this.props;

    this.map.on("moveend", function (e) {
      const boundedMarkers = markers.filter((marker) => {
        return e.target.getBounds().contains(L.latLng(marker[0], marker[1]));
      });
      onMapBoundedMarkers(boundedMarkers, e.target.getBounds());
    });

    const map = this.map;
    let prevZoom = 8;
    map.on("zoomend", function (e) {
      const toastId = null;
      const activatedToastId = "activatedToastId";
      const deactivatedToastId = "deactivatedToastId";
      const zoomLevel = map.getZoom();
      if (zoomLevel === 16 && prevZoom < zoomLevel) {
        // zooming in
        prevZoom = zoomLevel;
        toast.info("Parcel layer Activated", {
          toastId: activatedToastId,
        });
      }
      if (zoomLevel === 15 && prevZoom > zoomLevel) {
        // zooming out
        prevZoom = 7.5;
        toast.info("Parcel layer Deactivated", {
          toastId: deactivatedToastId,
        });
      }
    });

    // handles zoom feature of row
    if (this.props.zoomCoordinate !== prevProps.zoomCoordinate) {
      this.map.setView(getCorrectOrderLatLng(this.props.zoomCoordinate), 15);
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (
      this.props.data.length !== prevProps.data.length ||
      this.state.show !== prevState.show ||
      this.props.zoomCoordinate !== prevProps.zoomCoordinate ||
      this.props.resetBounds
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { columns } = this.props;
    console.log("Map rendered");

    return (
      <React.Fragment>
        {this.state.show && (
          <ModalDetails
            key={this.state.modalData.properties.cartodb_id}
            data={this.state.modalData}
            columns={columns}
            show={this.state.show}
            handleClose={this.handleClose}
          />
        )}
        <div className="col-12">
          <div
            data-tut="reactour__map"
            id="mapId"
            style={{
              height: "100%",
              overflow: "hidden",
            }}
          ></div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Map);
