import React from "react";

const ToastUndo = ({ id, undo, closeToast }) => {
  function handleClick() {
    undo(id);
    closeToast();
  }

  return (
    <div>
      <button className="btn btn-sm btn-warning" onClick={handleClick}>
        Undo
      </button>{" "}
      <span style={{ fontWeight: "bold" }}>Deleted Row</span>
    </div>
  );
};

export default ToastUndo;
