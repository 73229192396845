import React, { Component } from "react";
import _ from "lodash";
import "jspdf-autotable";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { Route, Redirect, Switch } from "react-router-dom";
import LoginForm from "./components/login";
import Main from "./components/main";
import NotFound from "./components/notFound";
import ProtectedRoute from "./components/common/protectedRoute";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <ToastContainer /> */}
        <Switch>
          <Route path="/login" component={LoginForm} />
          <ProtectedRoute path="/" component={Main} />
          <Route path="/not-found" component={NotFound} />
          <Redirect to="/not-found" />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
