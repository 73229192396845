import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "font-awesome/css/font-awesome.css";
import "react-awesome-query-builder/css/antd.less";
import "antd/dist/antd.css";
import "react-awesome-query-builder/css/styles.scss";
import "react-awesome-query-builder/css/compact_styles.scss";
import "./utils/object_extensions.exec";
import * as Sentry from "@sentry/browser";
import config from "./config";
import "@fortawesome/fontawesome-svg-core/styles.css";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: config.sentryDns
  });
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
