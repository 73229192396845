import React from "react";

const VisibleFeatures = ({ rowsCount }) => {
  return (
    <span className="text-muted" style={{ marginLeft: 20, marginTop: 10 }}>
      {rowsCount} visible features
    </span>
  );
};

export default VisibleFeatures;
