import React, { Component } from "react";

class Details extends Component {
  render() {
    const { data } = this.props;

    return (
      <div className="cards">
        {data.map(data => (
          <div key={data.properties.Link} className="card card-body">
            <div className="row">
              {this.props.columns.map(column => {
                if (column.isChecked) {
                  return (
                    <React.Fragment>
                      <p className="col-3 details-heading">{column.name}</p>
                      <p className="col-9 details-para">
                        {data.properties[column.key]}
                      </p>
                    </React.Fragment>
                  );
                }
              })}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Details;
