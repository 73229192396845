import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { CSVLink } from "react-csv";
import Workbook from "react-excel-workbook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudDownloadAlt,
  faFileAlt,
  faFileExcel,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";

class ExportDataDropdown extends Component {
  render() {
    return (
      <div
        className="btn-group"
        data-tut="reactour__export--table"
        role="group"
      >
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-export-data">
            <FontAwesomeIcon icon={faCloudDownloadAlt} /> Export Data
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <div className="listItem">
              <CSVLink data={this.props.csvData} filename={"table.csv"}>
                <FontAwesomeIcon icon={faFileAlt} /> CSV
              </CSVLink>
            </div>
            <div className="listItem">
              <Workbook
                filename="table.xlsx"
                element={
                  <div>
                    <FontAwesomeIcon icon={faFileExcel} /> Excel
                  </div>
                }
              >
                <Workbook.Sheet
                  data={this.props.excelData.excelBody}
                  name="Sheet A"
                >
                  {this.props.excelData.excelColumns.map((column) => (
                    <Workbook.Column
                      label={column.name}
                      value={column.key}
                      key={column.key}
                    />
                  ))}
                </Workbook.Sheet>
              </Workbook>
            </div>
            <div
              onClick={() => this.props.downloadPdf(this.props.rowsData)}
              className="listItem"
            >
              <FontAwesomeIcon icon={faFilePdf} /> PDF
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

export default ExportDataDropdown;
