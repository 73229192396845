import React, { useState } from "react";
import MenueButton from "./common/menueButton";
import { Modal, Button } from "react-bootstrap";
import DropZone from "./dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import config from "../config";

const AddRecord = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <MenueButton
        label="Add Records"
        btnClasses="btn btn-primary"
        onClick={handleShow}
        data-tut="reactour__upload--documents"
      >
        <FontAwesomeIcon icon={faPlus} />
      </MenueButton>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Document Records</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DropZone />
        </Modal.Body>
        <Modal.Footer>
          <span>
            As an example upload see{" "}
            <a
              className="btn btn-sm btn-outline-info"
              href={config.testShpLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              this sample Shapefile
            </a>
          </span>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AddRecord;
