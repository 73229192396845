import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import CustomToggle from "../utils/customeToggle";
import { capitalizeFirstLetter } from "../utils/capitalizeString";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchPlus,
  faLaptop,
  faThLarge,
  faGlobe,
  faTable,
} from "@fortawesome/free-solid-svg-icons";

class ViewDropDown extends Component {
  getViewName = (view) => capitalizeFirstLetter(view) + " View";

  render() {
    const { view, onViewChange } = this.props;

    return (
      <li className="nav-item dropdown">
        <Dropdown alignRight="false" data-tut="reactour__views">
          <Dropdown.Toggle
            as={CustomToggle}
            variant="secondary"
            id="dropdown-basic"
          >
            <FontAwesomeIcon icon={faLaptop} /> {this.getViewName(view)}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              className="text-primary"
              onClick={() => onViewChange("split")}
            >
              <FontAwesomeIcon icon={faThLarge} color="blue" /> Split View
            </Dropdown.Item>
            <Dropdown.Item
              className="text-primary"
              onClick={() => onViewChange("map")}
            >
              <FontAwesomeIcon icon={faGlobe} color="blue" /> Map View
            </Dropdown.Item>
            <Dropdown.Item
              className="text-primary"
              onClick={() => onViewChange("table")}
            >
              <FontAwesomeIcon icon={faTable} color="blue" /> Table View
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </li>
    );
  }
}

export default ViewDropDown;
