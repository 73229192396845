export default function convertToTableData(data, columns) {
  const tableBody = [];
  const tableHead = [];
  let rowData = [];
  data.map((row, i, arr) => {
    columns.map((column) => {
      if (column.isChecked) {
        if (arr.length - 1 === i) {
          tableHead.push(column.name);
        }
        rowData.push(row.properties[column.key]);
      }
    });
    tableBody.push(rowData);
    rowData = [];
  });
  return { tableBody, tableHead };
}
