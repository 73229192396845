const properties2table = (
  jsonObj,
  classes = ["table table-striped table-sm"],
  hasParcelReport = true
) => {
  console.log(jsonObj);
  const headerRow = `<th>Attribute Name</th><th>Attribute Value</th>`;
  const bodyRows = Object.entries(jsonObj)
    .filter(([key, value]) => !!value)
    .map(([key, value]) => `<tr><td>${key}</td><td>${value}</td></tr>`);
  let parcelReportLink = "";

  if (hasParcelReport) {
    parcelReportLink = `
      <table class="${classes.join(" ")}">
        <thead><tr>${headerRow}</tr></thead>
        <tbody>${bodyRows.reverse().pop()}</tbody>
      </table>`;
  }

  return `
  ${parcelReportLink}

  <button class="btn btn-sm btn-block btn-info" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
    Toggle attributes
  </button>

  <div id="collapseExample" class="collapse">
    <table class="${classes.join(" ")}">
      <thead><tr>${headerRow}</tr></thead>
      <tbody>${bodyRows.join("\n")}</tbody>
    </table>
  </div>`;
};

export default properties2table;
