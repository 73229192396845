import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import ModalDetails from "./modalDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Identity = ({ data, columns, history, location }) => {
  let showValue = false;
  const { cartodb_id } = data.properties;
  if (location.hash.length) {
    const id_param = location.hash.replace("#", "");
    showValue = id_param === cartodb_id.toString();
  }

  const [show, setShow] = useState(showValue);
  const handleClose = () => {
    setShow(false);
    history.push("/");
  };
  const handleShow = () => {
    setShow(true);
    history.push(`#${cartodb_id}`);
  };
  return (
    <React.Fragment>
      <a href={`#${cartodb_id}`} onClick={handleShow}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          title="info"
          color="blue"
          className="action-icon"
          data-tut="reactour__info--document"
        />
      </a>
      {show && (
        <ModalDetails
          show={show}
          handleClose={handleClose}
          columns={columns}
          data={data}
        />
      )}
    </React.Fragment>
  );
};

export default withRouter(Identity);
