import config from "../config";
import {
  allDocumentsQuery,
  relatedDocsQueryTemplate,
  insertDataQueryTemplate,
  deleteRowQueryTemplate,
  mapBoundQueryTemplate,
  rowQueryTemplate
} from "./sqlQueries";
import apiBuilder from "../utils/apiBuilder";
import query_render from "../utils/query_render";
import {
  DOC_TITLE,
  DOC_TYPE,
  MUNICIPALI,
  STATUS,
  YEAR_PREP,
  DESCRIPTION,
  YEAR_TO,
  STATUS_DES,
  LINK
} from "../constants/COLUMN_NAMES";

const base_url = config.apiEndpoint;

export function getAllDocumentsQuery() {
  const documnet_query = query_render(allDocumentsQuery, {
    DOC_TITLE,
    DOC_TYPE,
    MUNICIPALI,
    STATUS,
    YEAR_PREP,
    DESCRIPTION,
    YEAR_TO,
    STATUS_DES,
    LINK
  });
  return apiBuilder(base_url, {
    format: "geojson",
    api_key: config.apiKey,
    q: documnet_query
  });
}

export function getRelatedDocQuery(cartodb_id) {
  let relatedDocsQuery = encodeURIComponent(
    query_render(relatedDocsQueryTemplate, {
      cartodb_id,
      DOC_TITLE,
      DOC_TYPE,
      DESCRIPTION,
      LINK
    })
  );

  return apiBuilder(base_url, {
    api_key: config.apiKey,
    q: relatedDocsQuery
  });
}

export function getInsertDataQuery(columns, values) {
  return query_render(insertDataQueryTemplate, { columns, values });
}

export function getDeleteRowQuery(cartodb_id) {
  return query_render(deleteRowQueryTemplate, { cartodb_id });
}

export function getModalMapFitBounds(cartodb_id) {
  const mapBoundQuery = query_render(mapBoundQueryTemplate, { cartodb_id });
  return apiBuilder(base_url, {
    api_key: config.apiKey,
    q: mapBoundQuery
  });
}

export function getRowById(cartodb_id) {
  return query_render(rowQueryTemplate, { cartodb_id }).toString();
}
