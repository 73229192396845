import React, { Component } from "react";
import SearchBox from "./searchBox";
import ExportDataDropdown from "./exportDataDropdown";
import ColumnFilterDropdown from "./columnFilterDropdown";
import MenueButton from "./common/menueButton";
import VisibleFeatures from "./common/visibleFeatures";
import AddRecord from "./addRecord";
import FilterData from "./filterData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";

class TableMenue extends Component {
  render() {
    const {
      rowsCount,
      onSearch,
      columns,
      onColumnSelect,
      tableToggle,
      onTableToggle,
      csvData,
      excelData,
      downloadPdf,
      rowsData,
      whereQuery,
      searchQuery,
    } = this.props;

    return (
      <div className="row justify-content-center p-2">
        <div className="col-12">
          <div className="table-menue">
            <div className="left-table-menue">
              <div
                className="btn-group"
                role="group"
                aria-label="Button group with nested dropdown"
              >
                <FilterData whereQuery={whereQuery} />
                <ExportDataDropdown
                  rowsData={rowsData}
                  csvData={csvData}
                  downloadPdf={downloadPdf}
                  excelData={excelData}
                />
                <AddRecord />
              </div>
              <VisibleFeatures rowsCount={rowsCount} />
            </div>

            <div className="d-flex justify-content-end">
              <SearchBox value={searchQuery} onSearch={onSearch} />
              <div className="btn-group m-2" id="right-btn-group">
                <MenueButton
                  label=""
                  btnClasses="btn btn-secondary"
                  onClick={onTableToggle}
                  data-tut="reactour__toggle--table"
                >
                  <FontAwesomeIcon
                    icon={tableToggle ? faToggleOn : faToggleOff}
                  />
                </MenueButton>
                <ColumnFilterDropdown
                  columns={columns}
                  onColumnSelect={onColumnSelect}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TableMenue;
