import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import renderCell from "../utils/renderCell";
import IdentityMap from "./identityMap";
import http from "../services/httpService";
import ModalTable from "./modalTable";
import _ from "lodash";
import { getRelatedDocQuery } from "../services/sqlService";
import { DOC_TITLE, DESCRIPTION, LINK } from "../constants/COLUMN_NAMES";

const ModalDetails = ({ data, columns, show, handleClose }) => {
  const [rows, setRows] = useState([]);

  const cartodb_id = data.properties.cartodb_id;

  useEffect(() => {
    if (show) {
      http
        .get(getRelatedDocQuery(cartodb_id))
        .then(({ data }) => setRows(data.rows));
    }
  }, [show]);

  const tableColumns = _.filter(
    columns,
    (column) => column.key === DESCRIPTION || column.key === LINK
  );

  tableColumns.unshift({
    key: DOC_TITLE,
    name: "Document Title",
    minWidth: 150,
    isChecked: true,
    content: (doc_title, id) => {
      return (
        <a href={`#${id}`} target="_blank">
          {doc_title}
        </a>
      );
    },
  });
  return (
    <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
      <Modal.Header closeButton>
        <Modal.Title>Planning Document Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col">
              <table className="table table-striped table-bordered">
                <tbody>
                  {columns.map((column, i) => (
                    <tr key={i}>
                      <th>{column.name}</th>
                      <td style={{ wordBreak: "break-all" }}>
                        {renderCell(data, column)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row identityMap">
            <IdentityMap cartodb_id={cartodb_id} />
          </div>
          <div className="row">
            <div className="col">
              <br />
              <h5>Related Documents</h5>
              <ModalTable rows={rows} columns={tableColumns} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDetails;
