import React from "react";
import { CARTODB_ID } from "../constants/COLUMN_NAMES";
const ModalTable = ({ rows, columns }) => {
  const renderCell = (item, column) => {
    if (column.content) {
      return column.content(item[column.key], item[CARTODB_ID]);
    }
    return item[column.key];
  };

  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.key} scope="col">
              {column.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.doc_title}>
            {columns.map((column) => (
              <td key={column.name}>{renderCell(row, column)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ModalTable;
