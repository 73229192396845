import React, { useState } from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import NavListItem from "./common/navListItem";
import AboutTheProject from "./aboutTheProject";
import Support from "./support";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const About = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <NavListItem label="About" onClick={handleShow}>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </NavListItem>

      <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <Modal.Title>Planning Documents Management System</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
            <Tab eventKey="home" title="&#x2139; About the Project">
              <AboutTheProject />
            </Tab>
            <Tab eventKey="profile" title="&#x1F469;&#x200D;&#x1F393; Support">
              <Support />
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default About;
