const getCorrectOrderLatLng = coordinates => {
  let marker = [...coordinates];
  if (marker[0] > 0) {
    marker = marker.reverse();
  }

  return marker;
};

export default getCorrectOrderLatLng;
