export const allDocumentsQuery = `SELECT ST_PointOnSurface(ST_MakeValid(the_geom)) AS the_geom, cartodb_id, {{DOC_TITLE}}, {{DOC_TYPE}}, {{DESCRIPTION}}, {{STATUS}}, {{MUNICIPALI}}, {{YEAR_PREP}}, {{YEAR_TO}}, {{LINK}}, {{STATUS_DES}} FROM admin.gor_strategic_doc_georef ORDER BY cartodb_id ${
  process.env.NODE_ENV !== "production" ? "" : ""
}`;

export const mapBoundQueryTemplate = `SELECT ST_Extent(ST_MakeValid(the_geom)) AS extent FROM gor_strategic_doc_georef WHERE cartodb_id={{cartodb_id}}`;

export const rowQueryTemplate = `SELECT * FROM gor_strategic_doc_georef WHERE cartodb_id = {{cartodb_id}}`;

export const deleteRowQueryTemplate = `DELETE FROM admin.gor_strategic_doc_georef WHERE cartodb_id={{cartodb_id}}`;

export const insertDataQueryTemplate = `INSERT INTO admin.gor_strategic_doc_georef {{columns}} VALUES {{values}}`;

export const relatedDocsQueryTemplate = `SELECT selected_cartodb_id, cartodb_id, {{DOC_TITLE}}, {{DOC_TYPE}}, {{DESCRIPTION}}, {{LINK}} FROM (
  WITH selected_region AS (
    SELECT cartodb_id AS selected_cartodb_id,
      ST_MakeValid(the_geom) AS selected_the_geom
    FROM admin.gor_strategic_doc_georef a
    WHERE a.cartodb_id = {{cartodb_id}}
  ) SELECT * FROM selected_region
    JOIN
      gor_strategic_doc_georef ON selected_region.selected_the_geom && ST_MakeValid(gor_strategic_doc_georef.the_geom) 
      AND ST_Within (
        selected_region.selected_the_geom,
        gor_strategic_doc_georef.the_geom
      )
  ) AS everything`;
