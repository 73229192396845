export const CARTODB_ID = "cartodb_id";
export const DOC_TITLE = "doc_title";
export const DOC_TYPE = "doc_type";
export const MUNICIPALI = "municipali";
export const STATUS = "status";
export const YEAR_PREP = "year_prepa";
export const DESCRIPTION = "description";
export const YEAR_TO = "year_to";
export const STATUS_DES = "status_des";
export const LINK = "link";

export const COLUMN_NAMES = [
  DOC_TITLE,
  DOC_TYPE,
  MUNICIPALI,
  STATUS,
  YEAR_PREP,
  DESCRIPTION,
  YEAR_TO,
  STATUS_DES,
  LINK
];
