import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./common/input";
import { login } from "../services/authService";
import { isLoggedIn } from "../services/authService";
import { Redirect } from "react-router-dom";
import config from "../config";
import { faUser, faLock, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/Final-Geografia-Logos_White.png";

class LoginForm extends Component {
  state = {
    account: { username: "", password: "" },
    errors: {},
  };

  schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
  };

  validate = () => {
    const { error } = Joi.validate(this.state.account, this.schema, {
      abortEarly: false,
    });
    if (!error) return null;

    const errors = {};

    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    console.log(errors);
    this.setState({ errors: errors || {} });

    if (errors) return;
    //Call the Server
    this.doSubmit();
  };

  doSubmit = async () => {
    try {
      const { account } = this.state;
      const { data } = await login(account.username, account.password);
      localStorage.setItem("token", data["api_key"]);
      localStorage.setItem("user", JSON.stringify(data));
      localStorage.setItem("isTourOpen", "true");

      const { state } = this.props.location;

      window.location = state ? `/${state.from.hash}` : "/";
    } catch (ex) {
      if (ex.response && ex.response.status !== 200) {
        const errors = { ...this.state.errors };
        errors.username = "Invalid Username or Password";
        this.setState({ errors });
      }
    }
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const account = { ...this.state.account };
    account[input.name] = input.value;
    this.setState({ account, errors });
  };
  render() {
    if (isLoggedIn()) return <Redirect to="/" />;
    const { account, errors } = this.state;
    return (
      <div style={{ height: "100vh", backgroundColor: "#e4e5e6" }}>
        <main className="container">
          <div className="d-flex justify-content-center">
            <div className="card-group row" style={{ marginTop: 20 }}>
              <div
                className="card col-4"
                style={{ backgroundColor: "#201645", padding: 5 }}
              >
                <a
                  href="https://geografia.com.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    width="262.5"
                    height="70"
                    src={logo}
                    alt="Card image cap"
                  />
                </a>
                <div className="card-body">
                  <div className="css-typing" style={{ color: "white" }}>
                    <p>Planning Documents</p>
                    <p>Management System</p>
                    <p>(PDMS)</p>
                  </div>
                </div>
              </div>
              <div
                className="card col-8"
                style={{ width: "35rem", padding: 20 }}
              >
                <p className="login__signin--heading">Sign in</p>
                {/* <p className="text-muted">Sign In to your account</p> */}
                <form onSubmit={this.handleSubmit}>
                  <Input
                    name="username"
                    value={account.username}
                    label="Username"
                    onChange={this.handleChange}
                    error={errors.username}
                    type="text"
                    icon={faUser}
                  />
                  <Input
                    name="password"
                    value={account.password}
                    label="Password"
                    onChange={this.handleChange}
                    error={errors.password}
                    type="password"
                    icon={faLock}
                  />
                  <div className="d-flex">
                    <div>
                      <button
                        disabled={this.validate()}
                        className="btn btn-secondary"
                        style={{ backgroundColor: "#201645" }}
                      >
                        <FontAwesomeIcon icon={faSignInAlt} /> Login
                      </button>
                    </div>
                    <div className="ml-auto">
                      <a href={config.forgotPasswordMailTo}>
                        <button
                          type="button"
                          className="btn btn-link text-dark px-0"
                        >
                          Forgot password?
                        </button>
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default LoginForm;
